<template>
    <div class="page">
        <div class="query-form">
            <el-tabs v-model="activeName" size="small">
                <el-tab-pane label="档案操作日志" name="0"></el-tab-pane>
                <el-tab-pane label="资源下载操作日志" name="1"></el-tab-pane>
                <el-tab-pane label="系统操作日志" name="2"></el-tab-pane>
            </el-tabs>
        </div>
        <RecordLog v-if="activeName == 0"></RecordLog>
        <DownloadLog v-if="activeName == 1"></DownloadLog>
        <SysLog v-if="activeName == 2"></SysLog>
    </div>
</template>

<script>
    import RecordLog from './recordLog'
    import DownloadLog from './downloadLog'
    import SysLog from './sysLog'
    export default {
        components: {RecordLog, DownloadLog, SysLog},
        data() {
            return {
                activeName: '0'
            }
        },
        methods: {
        }
    }
</script>

<style scoped>
</style>
