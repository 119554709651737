<template>
    <div>
        <el-form class="query-form" size="small" :inline="true" ref="searchForm" :model="searchForm"
                 label-width="100px" @keyup.enter.native="refreshList(1)">
            <el-form-item prop="operId" label="操作人：">
                <el-select v-model="searchForm.operId" filterable placeholder="请选择" clearable>
                    <el-option v-for="item in userList" :key="item.id" :label="item.name"
                               :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="search" label="操作模块：">
                <el-input v-model.trim="searchForm.search" placeholder="请输入操作模块" clearable maxlength="100"></el-input>
            </el-form-item>
            <el-form-item prop="type" label="操作类型：">
                <el-select v-model="searchForm.type" clearable placeholder="操作类型">
                    <el-option v-for="item in $dictUtils.getDictList('log_type')" :key="item.label" :label="item.label"
                               :value="item.label">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="start" label="操作时间：">
                <el-date-picker
                        clearable
                        v-model="searchDates"
                        type="datetimerange"
                        range-separator="至"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="refreshList(1)" icon="el-icon-search">查询</el-button>
                <el-button @click="resetSearch()" icon="el-icon-refresh-right">重置</el-button>
            </el-form-item>
            <div class="f_r">
                <el-button size="small" v-show="hasPermission('record:log:export')" @click="exportDataAll()"><i
                        class="icon-piliangdaochu iconfont buIcon"/>全部导出
                </el-button>
                <el-button size="small" v-show="hasPermission('record:log:export')" @click="exportData()"><i
                        class="icon-piliangdaochu iconfont buIcon"/>本页导出
                </el-button>
            </div>
        </el-form>
        <div class="bg-white">
            <el-table :data="dataList" v-loading="loading"
                      size="small" height="calc(100vh - 430px)" ref="multipleTable" class="table">
                <el-table-column prop="createTime" show-overflow-tooltip label="操作时间">
                </el-table-column>
                <el-table-column prop="operName" show-overflow-tooltip label="操作人">
                </el-table-column>
                <el-table-column prop="operIp" show-overflow-tooltip label="操作人IP">
                </el-table-column>
                <el-table-column prop="operModular" show-overflow-tooltip label="操作模块">
                </el-table-column>
                <el-table-column prop="operType" show-overflow-tooltip label="操作类型">
                </el-table-column>
                <el-table-column prop="operResult" show-overflow-tooltip label="操作结果">
                </el-table-column>
                <el-table-column prop="parameter" show-overflow-tooltip label="请求参数">
                </el-table-column>
            </el-table>
            <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
                           :current-page="page"
                           :page-sizes="[10, 20, 50, 100]" :page-size="size" :total="total" background
                           layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                searchForm: {
                    operId: "",
                    search: '',
                    type: "",
                    start: '',
                    end: '',
                },
                page: 1,
                size: 10,
                searchDates: [],
                userList: [],
                dataList: [],
                total: 0,
                loading: false,
                params: 1,
            }
        },
        mounted() {
            this.getUserList()
            this.refreshList(1)
        },
        methods: {
            // 获取数据列表
            refreshList(type) {
                if (type == 1) {
                    this.page = 1
                }
                this.loading = true
                let p = JSON.parse(JSON.stringify(this.searchForm))
                p.start = this.searchDates ? this.searchDates[0] : ''
                p.end = this.searchDates ? this.searchDates[1] : ''
                this.$axios(this.api.auth.findList, {
                    paramsJson: JSON.stringify(p),
                    page: this.page,
                    size: this.size
                }, 'get').then(data => {
                    this.dataList = data.data.records
                    this.total = parseInt(data.data.total)
                    this.loading = false

                })
            },

            // 获取用户列表
            getUserList() {
                this.$axios(this.api.auth.sysuserQueryAllList, {
                    current: 1,
                    size: 9999,
                    orderBy: '',
                    name: '',
                    loginName: '',
                }, 'get').then(data => {
                    if (data && data.status) {
                        this.userList = data.data.records
                    }
                })
            },

            // 导出
            exportData() {
                let idList = this.dataList.map(item => {
                    return item.id
                })
                this.exportExcel(this.api.record.exportEsLog, idList, '系统操作日志', 'post')
            },

            // 导出全部
            exportDataAll() {
                const h = this.$createElement;
                this.$msgbox({
                    title: '温馨提示',
                    message: h('p', null, [
                        h('p', {style: 'color: #000000; margin-bottom: 5px'}, '确定导出所筛选后日志数据？'),
                        h('p', {style: 'color: #999999; font-size: 12px'}, '注意：当所筛选的日志数据大于20000条时，只导出最新的20000条日志')
                    ]),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(() => {
                    let p = JSON.parse(JSON.stringify(this.searchForm))
                    p.start = this.searchDates && this.searchDates.length ? this.searchDates[0] : ''
                    p.end = this.searchDates && this.searchDates.length ? this.searchDates[1] : ''
                    this.exportExcel(this.api.record.exportEsLogAll, {
                        "createEndDate": p.end,
                        "createStartDate": p.start,
                        "operId": p.operId,
                        "operModular": p.search,
                        "operType": p.type,
                    }, '系统操作日志', 'post')
                })
            },

            // 每页数
            sizeChangeHandle(val) {
                this.size = val
                this.page = 1
                this.refreshList()

            },

            // 当前页
            currentChangeHandle(val) {
                this.page = val
                this.refreshList()
            },

            resetSearch() {
                this.searchDates = []
                this.$refs.searchForm.resetFields()
                this.refreshList(1)
            }
        }
    }
</script>
